.container
    width: 100%
    background-repeat: no-repeat
    background-size: 100% 100%
    background-color: #1F6FFF

    .contentTai
        .content
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
            >.items
                display: flex
                align-items: center
                >.item
                    display: flex
                    align-items: center
                    flex-direction: column

                    .icon
                        width: 8.8rem
                        height: 8.8rem
                        margin-bottom: 3.2rem
                    .title
                        font-size: 2rem
                        color: #333333
                        margin-bottom: 1.6rem
                        font-weight: 600
                    .text
                        font-size: 1.6rem
                        color: #666666
                        line-height: 2.8rem
                        text-align: center
@media screen and ( min-width:  769px)
    .itemTitlePc
        height: 3.2rem
        margin-bottom: 9.6rem
        margin-top: 9.6rem
    .contentOne
        background: #fff
    .contentfive
        background: #fff
        .oneH5
            display: none
        .item
            height: 27.3rem
            position: relative
            >.circle
                display: none
            &:nth-child(4)
                >.line
                    display: none !important
            >.line
                position: absolute
                top: 7rem
                right: -6rem
                width: 12rem
                height: 0rem
                border: #1F6FFF 0.1rem dashed
                z-index: 10

        .icon
            width: 15rem !important
            height: 15rem !important
            margin-bottom: 0 !important
        .text
            padding: 0 3rem
        .title
            text-align: center
    .contentTwo
        background: #F6F9FF
    .contenthree
        background: #fff
        .hint
            font-size: 0.6rem
            color: #999999
            line-height: 1.4rem
            transform: translateY(-7rem)
    .contenFour
        background: #fff
        background-image: url('~@/assets/newImages/pc/anBg.png')
        background-size: 100%
        background-repeat: no-repeat
        height: 84.7rem
        .hint
            font-size: 1.6rem
            color: #FFFFFF
            line-height: 2.8rem
            transform: translateY(-1rem)
        .items4
            margin-top: 5.6rem
            width: 100%
            background-color: #fff
            display: flex
            align-items: center
            justify-content: space-between
            border-radius: 0.8rem
            padding: 4rem
            box-sizing: border-box
            >.item
                >.title
                    font-size: 2rem
                    color: #333333
                    font-weight: bold
                    margin-bottom: 2.4rem
                >.texts
                    width: 54rem
                    height: 29rem
                    background: #F8F8F8
                    border-radius: 0.8rem
                    padding: 3.2rem
                    box-sizing: border-box
                    >.row
                        display: flex
                        align-items: flex-start
                        >.circle
                            min-width: 2.4rem
                            min-height: 2.4rem
                            background: #C2C2C2
                            border-radius: 50%
                            display: flex
                            align-items: center
                            justify-content: center
                            color: #fff
                            margin-right: 2rem
                        >.text
                            font-size: 1.6rem
                            color: #666666
                            line-height: 2.8rem
                            margin-bottom: 3rem
            >.blueItem
                >.title
                    color: #1F6FFF
                >.texts
                    background: linear-gradient(180deg, #FBFCFF 0%, #F5F8FF 100%)
                    border-radius: 0.8rem 0.8rem 0.8rem 0.8rem
                    border: 0.2rem solid rgba(33,98,254,0.08)
                    >.row
                        >.circle
                            background: #1F6FFF
                        >.text
                            color: #1F6FFF
    .contentTai
        .content
            padding-bottom: 12rem
            >.items
                >.item
                    >.icon
                        width: 8.8rem
                        height: 8.8rem
            >.items2
                flex-wrap: wrap
                >.item
                    width: 33.3%
                    margin-bottom: 4.8rem
                    .icon
                        width: 16rem !important
                        height: 14.4rem !important
                    .title
                        text-align: center
                        >.blueNum
                            color: #1F6FFF
                    .text
                        padding: 0 3rem
            >.items3
                width: 100%
                justify-content: space-between
                >.item
                    width: 28.2rem
                    height: 15.4rem
                    background: rgba(31,111,255,0.04)
                    border-radius: 0.8rem 0.8rem 0.8rem 0.8rem
                    display: flex
                    align-items: center
                    justify-content: center
                    >.title
                        color: #1F6FFF !important
                        font-size: 3.2rem !important
                    >.textH5
                        display: none

            >.bottomImg
                display: none
    .footer
        height: 19rem
        background: #282C34
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        .text
            font-size: 1.6rem
            color: #FFFFFF
            line-height: 2rem
        .one
            margin-right: 10rem
        .three
            transform: translateY(2rem)
        .four
            margin-bottom: 3rem
        a
            color: #FFF
            text-decoration: none
            &:first-child
                // padding-right: 2rem
            &:hover
                color: #1F6FFF
@media screen and ( max-width:  768px)
    .itemTitlePc
        margin-top: 4.4rem
        margin-bottom: 4rem
    .contenthree
        position: relative
        .content
            padding-bottom: 3rem !important
        .hint
            font-size: 1rem
            color: #999999
            position: absolute
            bottom: 1rem
            z-index: 99
    .contentTai
        padding: 8.8rem 3.2rem
        box-sizing: border-box
        margin-bottom: 5.2rem
        .content
            background-color: #fff
            border-radius: 1rem 1rem 0 0
            padding: 0 1.6rem
            box-sizing: border-box
            position: relative
            >.onePc
                width: 28.8rem
            >.twoPc
                width: 17.86rem
            >.items
                flex-wrap: wrap
                >.item
                    width: 50%
                    margin-bottom: 3.2rem
                    .icon
                        width: 5.6rem
                        height: 5.6rem
                        margin-bottom: 2rem
                    .title
                        font-size: 1.7rem
                    .text
                        font-size: 1.4rem
                        z-index: 9
            >.items2
                >.item
                    width: 100%
                    align-items: start !important
                    .icon
                        width: 11.6rem !important
                        height: 10.2rem !important
                    .text
                        text-align: left !important
                    .blueNum
                        color: #1F6FFF
                    &:nth-child(even)
                        flex-direction: row !important
                        .icon
                            margin-right: 1.6rem

                    &:nth-child(odd)
                        flex-direction: row-reverse !important
                        .icon
                            margin-left: 1.6rem

                    >.icon
                        float: right
            >.items3
                width: 100%
                justify-content: space-between
                >.item
                    width: 48%
                    height: 12.25rem
                    background: rgba(31,111,255,0.04)
                    border-radius: 0.6rem 0.6rem 0.6rem 0.6rem
                    display: flex
                    align-items: center
                    justify-content: center
                    >.title
                        color: #1F6FFF !important
                        font-size: 2rem !important
                        margin-bottom: 1.4rem !important
                    >.textPc
                        display: none
                    >.textH5
                        font-size: 1.4rem

            >.bottomImg
                width: 100%
                transform: scaleX(1.006)
                position: absolute
                bottom: -3.5rem
    .contenFour
        >.content
            background: #1F6FFF
            padding: 0
            >.hint
                font-size: 1.5rem
                color: #FFFFFF
                line-height: 2.8rem
                margin-bottom: 2.8rem
            >.items4
                // height: 64rem
                background: #FFFFFF
                border-radius: 0.8rem 0.8rem 0.8rem 0.8rem
                width: 100%
                padding: 3.6rem 1.6rem
                box-sizing: border-box
                >.item
                    >.title
                        font-size: 1.7rem
                        font-weight: bold
                        color: #333333
                        margin-bottom: 1.4rem
                    >.title2
                        margin-top: 3rem
                    >.texts
                        padding: 2.4rem 1.6rem 0 1.6rem
                        // width: 31.1rem
                        // height: 24.6rem
                        background: #F8F8F8
                        border-radius: 0.6rem 0.6rem 0.6rem 0.6rem
                        >.row
                            display: flex
                            align-items: flex-start
                            >.circle
                                min-width: 2.4rem
                                min-height: 2.4rem
                                background: #C2C2C2
                                border-radius: 50%
                                display: flex
                                align-items: center
                                justify-content: center
                                color: #fff
                                margin-right: 1.2rem
                            >.text
                                font-size: 1.4rem
                                color: #666666
                                margin-bottom: 2rem
                                line-height: 2.4rem
                >.blueItem
                    >.title
                        color: #1F6FFF
                    >.texts
                        background: linear-gradient(180deg, #FBFCFF 0%, #F5F8FF 100%)
                        border-radius: 0.8rem 0.8rem 0.8rem 0.8rem
                        border: 0.2rem solid rgba(33,98,254,0.08)
                        >.row
                            >.circle
                                background: #1F6FFF
                            >.text
                                color: #1F6FFF
    .contentfive
        display: flex
        flex-direction: column
        align-items: center
        .oneH5
            width: 28.8rem
            margin-top: -1.2rem !important
        .onePc
            display: none !important
        .content
            border-radius: 0.5rem
            padding-top: 2.8rem
        .items
            flex-direction: column
            >.item
                width: 100% !important
                flex-direction: row !important
                align-items: flex-start !important
                position: relative
                &:nth-child(4)
                    >.line
                        display: none !important
                >.icon
                    display: none
                >.circle
                    min-width: 0.5rem
                    min-height: 0.5rem
                    background: #fff
                    border: 0.6rem solid #1F6FFF
                    border-radius: 50%
                    box-sizing: content-box
                    margin-right: 2rem
                    margin-top: 0.4rem
                .title
                    font-size: 1.7rem !important
                .text
                    font-size: 1.35rem !important
                    text-align: left !important
                .line
                    position: absolute
                    top: 2.6rem
                    left: 0.8rem
                    width: 0rem
                    height: 9rem
                    border: rgba(31,111,255,0.4)0.1rem dashed
                    z-index: 10
    .footer
        background: #fff
        // height: 12.7rem
        padding-top: 2.4rem !important
        padding-bottom: 2.4rem !important
        color: #999
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        font-size: 1.1rem
        .row
            width: 100%
        .text
            width: 100%
            text-align: left
            display: block
            margin-bottom: 0.8rem
        .four
            display: flex
        a
            display: block
            color: #1F6FFF
            text-decoration: none
            white-space: nowrap
            &:first-child
                margin-right: 1rem
                padding-bottom: 1rem
        .dou
            display: none
