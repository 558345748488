
@media screen and ( min-width:  769px)
    .container
        position: fixed
        right: 0
        top: 50%
        .h5
            display: none
        .show
            width: 6.4rem
            height: 8.8rem
            background: #FFFFFF
            box-shadow: 0rem 0rem 4rem 0.1rem rgba(0,0,0,0.08)
            border-radius: 0.4rem 0.4rem 0.4rem 0.4rem
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            cursor: pointer
            .text
                font-size: 1.2rem
                color: #333333
                margin-top: 0.8rem
        .hide
            position: absolute
            right: 10rem
            top: -6rem
            width: 16.8rem
            height: 22.9rem
            background: #FFFFFF
            box-shadow: 0rem 0.8rem 4rem 0.1rem rgba(0,0,0,0.08)
            border-radius: 0.4rem 0.4rem 0.4rem 0.4rem
            border: 0.1rem solid #EEEEEE
            display: flex
            flex-direction: column
            align-items: center
            justify-content: space-between
            padding: 1.6rem 0
            .title
                font-size: 1.4rem
                .blue
                    color: #0179F4
            .text
                font-size: 1.2rem
                color: #666666
            .service
                width: 13.6rem
                height: 13.6rem
            .triangle
                width: 1.4rem
                height: 2.8rem
                position: absolute
                top: 9rem
                right: -1.4rem
        .dis
            display: none
    .model
        display: none
@media screen and ( max-width:  768px)

    .a
        display: none
    .container
        position: fixed
        right: 1rem
        top: 50%
        z-index: 999
        .pc
            display: none
        .h5
            width: 5.6rem
            height: 14.3rem
            background: #FFFFFF
            box-shadow: 0rem 0.2rem 4rem 0.05rem rgba(31,111,255,0.16)
            border-radius: 2.8rem 2.8rem 2.8rem 2.8rem
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            .item
                display: flex
                flex-direction: column
                justify-content: center
                align-items: center
                margin-bottom: 1.2rem
                .img
                    width: 2.4rem
                    height: 2.4rem
                    margin-bottom: 0.6rem
                .text
                    font-size: 1rem
                    color: #1F6FFF
    .model
        position: fixed
        top: 0
        left: 0
        width: 100%
        height: 100%
        background-color: rgba(#000000,0.4)
        display: flex
        justify-content: center
        align-items: center
        .serviceH5
            width: 65vw
            height: 65vw
            background-color: #fff
            padding: 1rem
            box-sizing: content-box
            border-radius: 1.5rem
    .hideModel
        display: none
