.container
    width: 100%
    background-repeat: no-repeat
    background-size: 100% 100%
@keyframes moveDown
    0%
        transform: translateX(-100%)
    100%
        transform: translateX(0)
@keyframes moveUp
    0%
        transform: translateX(100%)
    100%
        transform: translateX(10rem)
@media screen and ( min-width:  769px)

    .container
        background-image: url('~@/assets/images/pc/headerBg.png')
        >.content
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            height: 64rem
            animation: moveDown 1s

            >.logo
                width: 16.2rem
                height: 5.6rem
                margin-bottom: 5.6rem
            >.left
                >.title1
                    text-align: center
                    font-size: 4.8rem
                    font-weight: bold
                    color: #FFFFFF
                    text-shadow: 0px 0px 0px rgba(255,255,255,0.16)
                    margin-bottom: 4.8rem
                    line-height: 4.8rem

                >.title2
                    display: none
                >.text
                    width: 121rem
                    text-align: center
                    height: 5.9rem
                    font-size: 1.8rem
                    font-weight: 400
                    color: #FFFFFF
                    line-height: 3.4rem
@media screen and ( max-width:  768px)
    .container
        background-image: url('~@/assets/images/h5/headerBg.png')
        background-repeat: no-repeat
        background-size: 100%
        >.content
            animation: moveDown 1s
            >.logo
                margin-top: 3.6rem
                width: 8.1rem
                height: 2.8rem
            >.left
                width: 100%
                margin-bottom: 4.4rem
                >.title1
                    font-size: 3.1rem
                    font-weight: bold
                    color: #FFFFFF
                    line-height: 0rem
                    text-shadow: 0px 1px 0px rgba(255,255,255,0.16)
                    line-height: 3.1rem
                    margin-top: 5.6rem
                    margin-bottom: 2rem
                    >.pcTitle
                        display: none
                >.title2
                    font-size: 1.8rem
                    font-weight: 500
                    color: #FFFFFF
                    line-height: 0rem
                    margin-bottom: 3.2rem
                    line-height: 2.5rem
                    width: 32.7rem
                    height: 4.4rem
                    line-height: 4.4rem
                    padding-left: 1.2rem
                    background: linear-gradient(92deg, rgba(32,196,196,0.6) 0%, rgba(32,196,196,0) 100%)
                    letter-spacing: 0.5rem
                >.text
                    font-weight: 400
                    color: #FFFFFF
                    font-size: 1.5rem
                    font-weight: 400
                    line-height: 2.8rem
