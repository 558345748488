html
  font-size: 10px
  .app
    width: 100%
    overflow-x: hidden
  *::-webkit-scrollbar
    display: none

@media screen and ( min-width:  769px)

  .box
    width: 100%
    padding: 0 36rem
    box-sizing: border-box
    margin: 0 auto
@media screen and ( max-width:  768px)

  .box
    width: 100%
    padding: 0 1.6rem
    box-sizing: border-box
